export const environment = {
  defaultLang: 'it',
  production: true,
  apiGraphQlPath: '/api/graphql/',
  apiBasePath: '/api/v0/',
  apiBasePathDocuments: '/api/v0',
  loginFlowPath: '/api/v0/auth/google-signin',
  logoutFlowPath: '/api/v0/auth/signout',
  internalLoginFlowPath: '',
  applicationStudentPortalPath:
    'https://test.afsglobal.org/AFSGlobal/Administration/ITAExtension/SendingPortal/?crmId=',
  appVersion: import.meta.env?.NG_APP_VERSION,
  appRevision: import.meta.env?.NG_APP_REVISION,
} as const;
